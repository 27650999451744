const mobileEleTreeData = localStorage.getItem('mobileEleTreeData') || '{}';
const mobileAppListData = localStorage.getItem('mobileAppListData') || '[]';
function overSave(name, data) {
  localStorage.setItem(name, JSON.stringify(data));
}

export default {
  namespace: 'mobileEleModel',
  state: {
    mobileEleTreeData: JSON.parse(mobileEleTreeData),
    mobileAppListData: JSON.parse(mobileAppListData)
  },
  reducers: {
    initMobileEleTreeData(state, {payload}) {
      // let { mobileEleTree } = payload
      overSave('mobileEleTreeData', payload);
      return {
        ...state,
        payload
      };
    },
    clearMobileEleTreeData(state) {
      overSave('mobileEleTreeData', []);
      return {
        ...state,
        mobileEleTreeData: ''
      };
    },
    initMobileAppListData(state, payload) {
      let { appList } = payload
      let list = []
      appList.map((app)=>{
        list.push({
          value: app.packageName,
          desc: app.name,
        })
      })
      overSave('mobileAppListData', list);
      return {
        ...state,
        list
      };
    },
    clearMobileAppListData(state) {
      overSave('mobileAppListData', []);
      return {
        ...state,
        mobileAppListData: []
      };
    },
    loadMobileShotData(state, payload) {
      let { data } = payload
      overSave('mobileShotData', data);
      return {
        ...state,
        data
      };
    },
  },
  effects: {},
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname, query }) => { });
    }
  }
};
