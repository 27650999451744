import React from 'react';
import { Space, Spin } from 'antd';
import styles from './index.less';
// export default () => <div className="rotate-animate"></div>;
const App: React.FC = () => (
    <div className={styles.layout}>
        <div className={styles.loading}>
            <Space size="large">
                <Spin size="large" tip="努力加载中..."/>
            </Space>
        </div>
    </div>
);
  
export default App;
