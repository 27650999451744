// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Users/gavinsir/Workspace/qmzn/rapii-designer/fe/rpaii-designer-fe/renderer/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/LoadingCp';

export function getRoutes() {
  const routes = [
  {
    "exact": false,
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/index.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__app' */'/Users/gavinsir/Workspace/qmzn/rapii-designer/fe/rpaii-designer-fe/renderer/src/pages/app'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__app' */'/Users/gavinsir/Workspace/qmzn/rapii-designer/fe/rpaii-designer-fe/renderer/src/pages/app'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/app",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__app' */'/Users/gavinsir/Workspace/qmzn/rapii-designer/fe/rpaii-designer-fe/renderer/src/pages/app'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/editor",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__designer' */'/Users/gavinsir/Workspace/qmzn/rapii-designer/fe/rpaii-designer-fe/renderer/src/pages/designer'), loading: LoadingComponent}),
        "exact": true,
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})]
      },
      {
        "path": "/help",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__help' */'/Users/gavinsir/Workspace/qmzn/rapii-designer/fe/rpaii-designer-fe/renderer/src/pages/help'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login' */'/Users/gavinsir/Workspace/qmzn/rapii-designer/fe/rpaii-designer-fe/renderer/src/pages/login'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/mobileTip",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mobileTip' */'/Users/gavinsir/Workspace/qmzn/rapii-designer/fe/rpaii-designer-fe/renderer/src/pages/mobileTip'), loading: LoadingComponent}),
        "exact": true,
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})]
      },
      {
        "path": "/loading",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__LoadingCp' */'/Users/gavinsir/Workspace/qmzn/rapii-designer/fe/rpaii-designer-fe/renderer/src/components/LoadingCp'), loading: LoadingComponent}),
        "exact": true,
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})]
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
