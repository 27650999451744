const componentData = localStorage.getItem('componentData') || '[]';
const varpolData = localStorage.getItem('varpolData') || '[]';
const logData = localStorage.getItem('logData') || '[]';

function overSave(name, data) {
    localStorage.setItem(name, JSON.stringify(data));
}

export default {
    namespace: 'componentModal',
    state: {
        componentData: JSON.parse(componentData),
        varpolData: JSON.parse(varpolData),
        logData: JSON.parse(logData),
    },
    reducers: {
        initComponentData(state, payload) {
            let { componentList } = payload
            overSave('componentData', componentList);
            return {
                ...state,
                componentList
            };
        },
        initLogData(state, payload) {
            let { logData } = payload
            overSave('logData', logData);
            return {
                ...state,
                logData
            };
        },
        clearLogData(state) {
            overSave('logData', []);
            return {
                ...state,
                logData: []
            };
        },
        addToVarPol(state, { payload }) {
            let varpolData = [...state.varpolData, payload];
            overSave('varpolData', varpolData);
            return {
                ...state,
                varpolData
            };
        },
        modVarPol(state, { payload }){
            const { item } = payload;
            const varpolData = state.varpolData.map(element => {
                if (element.item.key === item.key) {
                    return payload;
                }
                return { ...element };
            });
            if(item==null){
                payload = null
            }
            overSave('varpolData', varpolData);
            return {
                ...state,
                varpolData
            };
        },
        delVarPol(state, { payload }){
            let { item } = payload
            const varpolData = state.varpolData.filter(element => element.item.key !== item);
            overSave('varpolData', varpolData);
            return {
                ...state,
                varpolData
            };
        },
        clearAllVarPol(state) {
            overSave('varpolData', []);
            return {
                ...state,
                varpolData: []
            };
        },
        
    },
    effects: {},
    subscriptions: {
        setup({ dispatch, history }) {
            return history.listen(({ pathname, query }) => { });
        }
    }
};
