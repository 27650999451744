import type { Reducer } from 'umi';


export type webSocketItem = {
  authWebSoketIns?: WebSocket | null;
  busWebSocketIns?: {
    ws?: WebSocket | null,
    detect?: () => void,
    onLuanchUiAuto?: () => void,
    getMobileAppList?: () => void,
    getMobileDomTree?: () => void,
    onSave?: () => void,
    onSelectFlowData?: () => void,
    sendExecJob?: () => void,
    stopWebrtc: () => void,
    refreshWebrtc: () => void,
    onShotScreen?:() => void,
  };
};

export type busWsState = {
  triggerMobileDomTreeChanged: boolean,
  triggerMobileCapture: boolean;
  triggerMobileItemCapture: boolean;
  triggerMobileScreenShot: boolean,
  triggerWebrtcBlackScreenState:boolean;
  triggerSimilarChecked:boolean,
  similarity:0,
  targetNode:any,
  device:any;
  onlineListData: any;
  webrtcConnectState:string;
  webSocketIns: webSocketItem;
  logs?: "";
  mobileShotData?: any;
};

export type WsModelType = {
  namespace: 'busWxModel';
  state: busWsState;
  effects: any;
  reducers: {
    setTriggerMobileCapture:Reducer<busWsState>;
    setTriggerMobileItemCapture:Reducer<busWsState>;
    setTriggerMobileDomTreeChanged:Reducer<busWsState>;
    setTriggerMobileScreenShot:Reducer<busWsState>;
    setTriggerWebrtcBlackScreenState:Reducer<busWsState>;
    setTriggerSimilarChecked:Reducer<busWsState>;
    setDevice: Reducer<busWsState>;
    setOnLineList: Reducer<busWsState>;
    setWebrtcConnectState: Reducer<busWsState>;
    setBusWebSocketIns: Reducer<busWsState>;
  };
};
const onlineListData = localStorage.getItem('onlineList') || '[]';
const webrtcConnectState = localStorage.getItem('webrtcConnectState') || 'closed';

function overSave(name:string, data:any) {
    localStorage.setItem(name, JSON.stringify(data));
}
const BusWsModel: WsModelType = {
  namespace: 'busWxModel',

  state: {
    triggerMobileDomTreeChanged: false,
    triggerMobileCapture: false,
    triggerMobileItemCapture: false,
    triggerMobileScreenShot: false,
    triggerWebrtcBlackScreenState: false,
    triggerSimilarChecked:false,
    similarity:0,
    targetNode:undefined,
    device:{width:0,height:0},
    onlineListData: onlineListData,
    mobileShotData: undefined,
    webrtcConnectState: webrtcConnectState,
    webSocketIns: {
      authWebSoketIns: null,
      busWebSocketIns: {},
    }
  },

  effects: {},
  reducers: {
    setTriggerMobileCapture(state, payload: any): busWsState{
      const { trigger } = payload
      return {
        ...state,
        triggerMobileCapture: trigger
      }
    },
    setTriggerMobileItemCapture(state, payload: any): busWsState{
      const { trigger } = payload
      return {
        ...state,
        triggerMobileItemCapture: trigger
      }
    },
    setTriggerMobileDomTreeChanged(state, payload: any): busWsState{
      const { trigger } = payload
      return {
        ...state,
        triggerMobileDomTreeChanged:trigger
      }
    },
    setTriggerMobileScreenShot(state, payload: any): busWsState{
      const { trigger } = payload
      return {
        ...state,
        triggerMobileScreenShot:trigger
      }
    },
    setTriggerWebrtcBlackScreenState(state, payload: any): busWsState{
      const { trigger } = payload
      localStorage.setItem("webrtcBlackScreenState",trigger)
      return {
        ...state,
        triggerWebrtcBlackScreenState:trigger
      }
    },
    setTriggerSimilarChecked(state, {payload}): busWsState{
      const { trigger,targetNode,similarity } = payload
      return {
        ...state,
        targetNode,
        similarity,
        triggerSimilarChecked:trigger
      }
    },
    setDevice(state,payload:any):busWsState {
      const { device } = payload
      return {
        ...state,
        device:device
      }
    },
    setOnLineList(state, payload: any) :busWsState {
        const { onlineList } = payload
        overSave('onlineList', onlineList);
        return {
            ...state,
            onlineListData: onlineList
        };
    },
    setWebrtcConnectState(state, payload: any) :busWsState {
        const { connectState } = payload
        overSave('webrtcConnectState', connectState);
        return {
            ...state,
            webrtcConnectState: connectState
        };
    },
    setBusWebSocketIns(state, payload: any): busWsState {
      const { busWebSocketIns } = payload
      return {
        ...state,
        webSocketIns: {
          ...state?.webSocketIns,
          busWebSocketIns: busWebSocketIns
        }
      };
    },
    setMobileShotData(state, payload:any):busWsState {
      const { shotData } = payload
      return {
        ...state,
        mobileShotData: shotData
      }
    },
  },
};

export default BusWsModel;
