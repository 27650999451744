/*
 * @Description: 添加键盘快捷键
 * @Version: 2.1
 * @Autor: RPAII
 */
import { uniqueId } from '@/utils/tool';
import key from 'keymaster';

const pointData = localStorage.getItem('userData') || '[]';
const lineData = localStorage.getItem('lineData') || '[]';
const groupData = localStorage.getItem('groupData') || '[]';
const curPoint = localStorage.getItem('curPoint') || '[]';
const multipleSelectedData = localStorage.getItem('multipleSelectedData') || '[]';
const allExpanded = localStorage.getItem('allExpanded') || 'true';
const captureInfo = localStorage.getItem('captureInfo') || '{}';

function overSave(name, data) {
  localStorage.setItem(name, JSON.stringify(data));
}

export default {
  namespace: 'editorModal',
  state: {
    pointData: JSON.parse(pointData),
    lineData: JSON.parse(lineData),
    groupData: JSON.parse(groupData),
    multipleSelectedData: JSON.parse(multipleSelectedData),
    allExpanded: JSON.parse(allExpanded),
    curPoint: JSON.parse(curPoint),
    captureData: JSON.parse(captureInfo),
  },
  reducers: {
    modCaptureInfo(state, { payload }) {
      overSave('captureInfo', payload);
      return {
        ...state,
        captureData: payload
      }
    },
    setMultipleSelectedData(state, payload) {
      let { selected } = payload;
      overSave('multipleSelectedData', selected);
      return {
        ...state,
        selected,
      };
    },
    loadPointData(state, { payload }) {
      let { pointData } = payload;
      overSave('userData', pointData);
      return {
        ...state,
        pointData,
      };
    },
    addPointData(state, { payload }) {
      let { item, index, isStart } = payload;
      let pointData = [...state.pointData];
      if (isStart && index === 0) {
        // 加在第一个
        pointData.splice(0, 0, { item });
      } else if (index == -1 || index == undefined) {
        //添加到最后一个流程里
        pointData.splice(pointData.length, 0, { item });
      } else {
        //添加到指定位置
        pointData.splice(index + 1, 0, { item });
      }
      // let pointData = [...state.pointData, {item}];
      overSave('userData', pointData);
      return {
        ...state,
        pointData,
        curPoint: payload,
      };
    },
    upPointData(state, { payload }) {
      // debugger
      const { item, index, isStart, type } = payload;
      const pointData = state.pointData.filter(element => element.item.id !== item.id);
      if (isStart && index === 0) {
        // 加在第一个
        pointData.splice(0, 0, { item });
      } else if (index == -1 || index == undefined) {
        //添加到最后一个流程里
        pointData.splice(pointData.length, 0, { item });
      } else {
        //添加到指定位置
        pointData.splice(index + 1, 0, { item });
      }
      overSave('userData', pointData);
      return {
        ...state,
        pointData,
        curPoint: {item},
      };
    },
    editPointProps(state, { payload }) {
      // debugger
      const { item } = payload;
      const pointData = state.pointData.map(element => {
        if (element.item.id == item.id) {
          return payload;
        }
        return { ...element };
      });
      overSave('userData', pointData);
      return {
        ...state,
        pointData,
        curPoint: payload,
      };
    },
    modPointData(state, { payload }) {
      const { item,type } = payload;
      console.log("谁",type)
      const pointData = state.pointData.map(element => {
        if (element.item.id == item.id) {
          return {item}
            ;
        }
        return { ...element };
      });
      overSave('curPoint', {item});
      overSave('userData', pointData);
      return {
        ...state,
        pointData,
        curPoint: {item},
      };
    },
    selectedTreePointData(state, { payload }) {
      const { item } = payload;
      const pointData = state.pointData.map(element => {
        if (element.item.id === item.id) {
          return payload;
        }
        return { ...element };
      });
      if (item == null) {
        payload = null;
      }
      overSave('selectedTreePoint', payload);
      overSave('userData', pointData);
      return {
        ...state,
        selectedTreePoint: payload,
      };
    },
    importTplData(state, { payload }) {
      overSave('userData', payload);
      return {
        ...state,
        pointData: payload,
        curPoint: null,
      };
    },
    copyPointData(state, { payload }) {
      const { item } = payload;
      const pointData = [];
      state.pointData.forEach(element => {
        pointData.push({ ...element });
        if (element.id === item.id) {
          pointData.push({ ...element, id: uniqueId() });
        }
      });
      overSave('userData', pointData);

      return {
        ...state,
        pointData,
      };
    },
    delPointData(state, { payload }) {
      const { item } = payload;
      const pointData = state.pointData.filter(element => element.item.id !== item.id);
      overSave('userData', pointData);
      return {
        ...state,
        pointData,
        curPoint: null,
      };
    },
    batchDelPointData(state, { payload }) {
      // debugger
      const { nodeIds } = payload;
      const pointData = state.pointData.filter(element => !nodeIds.includes(element.item.id));
      overSave('userData', pointData);
      return {
        ...state,
        pointData,
        curPoint: null,
      };
    },
    delMuiltPointData(state, { payload }) {
      const { ids } = payload;
      let pointData = [];
      state.pointData.map((element) => {
        if (!ids.includes(element.item.id)) {
          pointData.push(element);
        }
      });
      overSave('userData', pointData);
      return {
        ...state,
        pointData,
        curPoint: null,
      };
    },
    keyboardCopyPointData(state) {
      if (state.curPoint) {
        const { id } = state.curPoint;
        const pointData = [];
        state.pointData.forEach(item => {
          pointData.push({ ...item });
          if (item.item.id === id) {
            pointData.push({ ...item, id: uniqueId() });
          }
        });
        overSave('userData', pointData);

        return {
          ...state,
          pointData,
        };
      }
      return state;
    },
    keyboardDelPointData(state) {
      if (state.curPoint) {
        const { id } = state.curPoint;
        const pointData = state.pointData.filter(item => item.item.key !== id);
        overSave('userData', pointData);
        return {
          ...state,
          pointData,
          curPoint: null,
        };
      }
      return state;
    },
    clearAll(state) {
      overSave('curPoint', {});
      overSave('userData', []);
      return {
        ...state,
        pointData: [],
        curPoint: null,
      };
    },
    addLineData(state, { payload }) {
      let lineData = [...state.lineData, payload];
      overSave('lineData', lineData);
      return {
        ...state,
        lineData,
        curLine: payload,
      };
    },
    clearAllLine(state) {
      overSave('lineData', []);
      return {
        ...state,
        pointData: [],
        curPoint: null,
      };
    },
    modLineData(state, { payload }) {
      const { item } = payload;
      const lineData = state.lineData.map(element => {
        if (element.item.id === item.id) {
          return payload;
        }
        return { ...element };
      });
      if (item == null) {
        payload = null;
      }
      overSave('lineData', lineData);
      return {
        ...state,
        lineData,
        curLine: payload,
      };
    },
    delLineData(state, { payload }) {
      let { id } = payload;
      const lineData = state.lineData.filter(element => element.item.id !== id);
      overSave('lineData', lineData);
      return {
        ...state,
        lineData,
        curLine: null,
      };
    },
    delMuiltLineData(state, { payload }) {
      const { ids } = payload;
      let lineData = [];
      state.lineData.map((element) => {
        if (!ids.includes(element.item.id)) {
          lineData.push(element);
        }
      });
      overSave('lineData', pointData);
      return {
        ...state,
        lineData,
        curLine: null,
      };
    },
    addGroupData(state, { payload }) {
      let groupData = [...state.groupData, payload];
      overSave('groupData', groupData);
      return {
        ...state,
        groupData,
        curGroup: payload,
      };
    },
    delGroupData(state, { payload }) {
      let { id } = payload;
      const groupData = state.groupData.filter(element => element.item.id !== id);
      overSave('groupData', groupData);
      return {
        ...state,
        groupData,
        curGroup: null,
      };
    },
    delMuiltGroupData(state, { payload }) {
      const { ids } = payload;
      let groupData = [];
      state.groupData.map((element) => {
        if (!ids.includes(element.item.id)) {
          groupData.push(element);
        }
      });
      overSave('groupData', groupData);
      return {
        ...state,
        groupData,
        curGroup: null,
      };
    },
    setAllExpanded(state, payload) {
      let { item } = payload;
      let allExpanded = { item: item };
      overSave('allExpanded', item);
      return {
        ...state,
        allExpanded,
      };
    },
    //设置targetId
    setGlobalTargetId(state, { payload }) {
      let { targetId } = payload;
      console.log(payload);
      overSave('targetId', targetId);
      return {
        ...state,
        targetId,
      };
    },
  },
  effects: {},
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname, query }) => {
      });
    },
    keyEvent({ dispatch, state }) {
      // 复制
      key('⌘+c, ctrl+c', () => {
        dispatch({
          type: 'editorModal/keyboardCopyPointData',
        });
      });
      // 删除
      key('delete, backspace', () => {
        dispatch({
          type: 'editorModal/keyboardDelPointData',
        });
      });
    },
  },
};
