import type { Reducer } from 'umi';


export type webSocketItem = {
  authWebSoketIns?: WebSocket | null;
  busWebSocketIns?: {
    ws?: WebSocket | null,
    detect?: () => void,
    setTarger?:(value:any)=>void,
    sendConnected?: ()=>void,
  };
};

export type cmcationodelState = {
  triggerMobileDomTreeChanged: boolean,
  triggerMobileCapture: boolean;
  triggerMobileItemCapture: boolean;
  webSocketIns: webSocketItem;
  logs?: "";
};

export type GlobalModelType = {
  namespace: 'cmcationModal';
  state: cmcationodelState;
  effects: any;
  reducers: {
    setTriggerMobileCapture:Reducer<cmcationodelState>;
    setTriggerMobileItemCapture:Reducer<cmcationodelState>;
    setTriggerMobileDomTreeChanged:Reducer<cmcationodelState>;
    setWebSocketIns: Reducer<cmcationodelState>;
  };
};

const GlobalModel: GlobalModelType = {
  namespace: 'cmcationModal',

  state: {
    triggerMobileDomTreeChanged: false,
    triggerMobileCapture: false,
    triggerMobileItemCapture: false,
    webSocketIns: {
      authWebSoketIns: null,
      busWebSocketIns: {},
    }
  },

  effects: {},
  reducers: {
    setTriggerMobileCapture(state, payload: any): cmcationodelState{
      const { trigger } = payload
      return {
        ...state,
        triggerMobileDomTreeChanged:false,
        triggerMobileCapture: trigger,
        triggerMobileItemCapture: false,
        webSocketIns:{}
      }
    },
    setTriggerMobileItemCapture(state, payload: any): cmcationodelState{
      debugger
      const { trigger } = payload
      return {
        ...state,
        triggerMobileDomTreeChanged:false,
        triggerMobileCapture: false,
        triggerMobileItemCapture: trigger,
        webSocketIns:{}
      }
    },
    setTriggerMobileDomTreeChanged(state, payload: any): cmcationodelState{
      const { trigger } = payload
      return {
        ...state,
        triggerMobileDomTreeChanged:trigger,
        triggerMobileCapture: false,
        triggerMobileItemCapture: false,
        webSocketIns:{}
      }
    },
    setWebSocketIns(state, payload: any): cmcationodelState {
      const { busWebSocketIns } = payload
      return {
        ...state,
        triggerMobileDomTreeChanged:false,
        triggerMobileCapture: false,
        triggerMobileItemCapture: false,
        webSocketIns: {
          ...state?.webSocketIns,
          busWebSocketIns: busWebSocketIns
        }
      };
    },
  },
};

export default GlobalModel;
